import React from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { b64toBlob } from "../../functions/index.js";
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { Flex, Box, Heading, Button, Text, Spinner } from "@chakra-ui/core";
/* import { b64toBlob } from "../helpers/functions"; */
import { CheckCircleIcon, ArrowUpIcon } from "@chakra-ui/icons";
import updateAction from "./updateAction";
import { animated } from "react-spring";

const ABox = animated(Box);

const LastStep = (props) => {
  const { handleBack, style, getValues } = props;

  const { state } = useStateMachine(updateAction);
  const [isDownloading, setIsDownloading] = React.useState(false);
  const { handleSubmit } = useForm();

  const onSubmit = () => {
    setIsDownloading(true);
    axios
      .post("https://www.pdf-api.accountant.hr/create-pdf", {
        name: "inkubatorIzjava",
        data: state.data,
      })
      .then((res) => {
        const url = window.URL.createObjectURL(
          b64toBlob(res.data, "application/pdf")
        );
        saveAs(
          url,
          `Izjava - registrirana tvrtka_o pocetku obavljanja djelatnosti.pdf`
        );
        setIsDownloading(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <ABox style={style}>
      <Flex maxW="950px" mx="auto" flexDir="column">
        <Heading fontSize={["lg", "xl"]} ml="10px" mx="auto" mt="2em">
          Dovršeno
        </Heading>
        <Box
          as={CheckCircleIcon}
          fill="green.500"
          color="green.500"
          mx="auto"
          my="2em"
          style={{ width: "5rem", height: "5rem" }}
        />
        <Text textAlign="center">
          Preuzmite PDF ili se vratite natrag na uređivanje.
        </Text>
      </Flex>

      <Flex
        w="100%"
        justify="space-between"
        flexWrap="wrap"
        maxW="900px"
        mx="auto"
        mt="15px"
        justifyContent="center"
      >
        {isDownloading ? (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        ) : (
          <Box m="15px">
            <Button
              onClick={() => handleBack(getValues)}
              colorScheme="gray"
              boxShadow="-5px -5px gray"
              mr={{ base: 0, md: "15px" }}
              border="0"
              _hover={{
                boxShadow: "0px 0px gray",
                backgroundColor: "gray.600",
                transform: "translateY(-5px)",
                color: "white",
              }}
            >
              <span>
                <ArrowUpIcon />
              </span>
              Natrag
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              colorScheme="green"
              boxShadow="-5px -5px green"
              border="0"
              _hover={{
                boxShadow: "0px 0px green",
                backgroundColor: "green.600",
                transform: "translateY(-5px)",
              }}
              mt={{ base: "2em", md: 0 }}
            >
              Preuzmi PDF
            </Button>
          </Box>
        )}
      </Flex>
      <Flex maxW="950px" mx="auto" flexDir="column">
        <Text textAlign="center" mt="20" color="blue.600" fontSize="18px">
          Izrada:{" "}
          <Box
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            textDecor="underline"
            href="http://accountant.hr"
          >
            accountant.hr
          </Box>
        </Text>
      </Flex>
    </ABox>
  );
};

export default LastStep;
