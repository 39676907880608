import React from "react";
import format from "date-fns/format";
import { hr } from "date-fns/locale";
import classnames from "classnames";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { InputGroup, InputRightElement, Input } from "@chakra-ui/core";
import { CalendarIcon } from "@chakra-ui/icons";
import { createMuiTheme } from "@material-ui/core";

import { ThemeProvider } from "@material-ui/styles";

import "./CustomDate.scss";

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      day: {
        color: "grey",
        "&:hover": {
          backgroundColor: "#9AE6B4 ",
        },
      },
      daySelected: {
        backgroundColor: "#35885c",
        "&:hover": {
          backgroundColor: "#276749",
        },
      },
      dayDisabled: {
        color: "grey",
      },
      current: {
        color: "#35885c",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "green.500",
      },
    },
  },
});

const CustomInput = (props) => {
  return (
    <Input
      onFocus={() => props.setFocus(true)}
      onBlur={() => props.setFocus(false)}
      value={props.value}
      onChange={props.onChange}
      borderColor="black"
    />
  );
};
const Toolbar = (props) => {
  return (
    <div className="toolbar-wrapper">
      <div className="box">
        <button
          className={classnames({
            item: true,
            "item-1": true,
            active: props.openView === "year",
          })}
          onClick={() => props.setOpenView("year")}
        >
          {props.date.getFullYear()}
        </button>
        <button
          className={classnames({
            item: true,
            "item-2": true,
            active: props.openView === "month",
          })}
          onClick={() => props.setOpenView("month")}
        >
          {format(new Date(props.date), "LLLL", {
            locale: hr,
          })}
        </button>
        <button
          className={classnames({
            item: true,
            "item-3": true,
            active: props.openView === "date",
          })}
          onClick={() => props.setOpenView("date")}
        >
          {format(new Date(props.date), "do MMM, yyyy", {
            locale: hr,
          })}
        </button>
      </div>
    </div>
  );
};

const CustomDate = (props) => {
  //const [selectedDate, setDate] = React.useState(new Date());
  const [isOpen, setIsOpen] = React.useState(false);
  const [isFocused, setFocus] = React.useState(false);
  return (
    <>
      <MuiPickersUtilsProvider
        className="datepicker"
        utils={DateFnsUtils}
        locale={hr}
      >
        <InputGroup
          className={classnames({ "custom-input": true, focused: isFocused })}
        >
          <ThemeProvider theme={materialTheme}>
            <KeyboardDatePicker
              {...props}
              open={isOpen}
              isFocused={isFocused}
              setFocus={setFocus}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
              format="dd.MM.yyyy"
              label="Basic example"
              /*  value={selectedDate}
            onChange={(date) => setDate(date)} */
              openTo="date"
              views={["date"]}
              TextFieldComponent={CustomInput}
              ToolbarComponent={Toolbar}
            />
          </ThemeProvider>
          <InputRightElement
            className="datepicker-icon"
            onClick={() => setIsOpen(!isOpen)}
            children={<CalendarIcon className="iconSvg" color="green.500" />}
          />
        </InputGroup>
      </MuiPickersUtilsProvider>
    </>
  );
};
export default CustomDate;
