import React from "react";
import NumberFormat from "react-number-format";
import {
  Box,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
} from "@chakra-ui/core";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { animated } from "react-spring";
import { Error } from "../style";
import { ArrowDownIcon } from "@chakra-ui/icons";

const ABox = animated(Box);

const KontaktPodaci = (props) => {
  const { style, state, control, errors, handleNext, handleSubmit } = props;

  return (
    <ABox style={style} pos="absolute" top={0}>
      <Flex flexWrap="wrap" mb={{ base: "3em", md: 0 }}>
        <Heading w="100%" mt="1em">
          Kontakt podaci
        </Heading>
        <Flex justifyContent="space-around" width="100%" flexWrap="wrap">
          <Box mt="1em" h="100px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="imePrezime">
                Ime i prezime
              </FormLabel>
              <Controller
                as={Input}
                borderColor={
                  errors.hasOwnProperty("imePrezime") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="imePrezime"
                placeholder="Ime i prezime"
                defaultValue={state.data.imePrezime}
                rules={{ required: "Ime i prezime je obvezno" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="imePrezime" />
          </Box>
          <Box mt="1em" h="100px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="naziv">
                Naziv tvrtke
              </FormLabel>
              <Controller
                as={Input}
                borderColor={errors.hasOwnProperty("naziv") ? "red.500" : ""}
                control={control}
                color="black"
                name="naziv"
                placeholder="Naziv tvrtke"
                defaultValue={state.data.naziv}
                rules={{ required: "Naziv tvrtke je obvezan" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="naziv" />
          </Box>
          <Box mt="1em" h="85px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="oibTvrtke">
                OIB (tvrtke/obrta)
              </FormLabel>
              <Controller
                as={
                  <NumberFormat
                    allowLeadingZeros
                    decimalSeparator={false}
                    type="text"
                    customInput={Input}
                    maxLength={11}
                  ></NumberFormat>
                }
                borderColor={
                  errors.hasOwnProperty("oibTvrtke") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="oibTvrtke"
                placeholder="OIB (tvrtke/obrta)"
                defaultValue={state.data.oibTvrtke}
                rules={{
                  required: "OIB (tvrtke/obrta) je obvezan",
                  minLength: {
                    value: 11,
                    message: "OIB tvrtke mora sadržavati najmanje 11 znakova",
                  },
                }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="oibTvrtke" />
          </Box>
          <Box
            mt={{ base: "2em", md: "1em" }}
            h={{ base: "95px", md: "85px" }}
            w={{ base: "100%", md: 1 / 2 }}
            px="15px"
          >
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="adresaTvrtke">
                Adresa
              </FormLabel>
              <Controller
                as={<Input />}
                borderColor={
                  errors.hasOwnProperty("adresaTvrtke") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="adresaTvrtke"
                placeholder="Adresa"
                defaultValue={state.data.adresaTvrtke}
                rules={{ required: "Adresa je obvezna" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="adresaTvrtke" />
          </Box>
        </Flex>
      </Flex>
      <Flex
        d={{ base: "none", md: "flex" }}
        m="auto"
        mt="2em"
        maxW="600px"
        justifyContent="space-around"
        mb="2em"
      >
        <Button
          onClick={handleSubmit(handleNext)}
          colorScheme="green"
          boxShadow="-5px -5px green"
          border="0"
          _hover={{
            boxShadow: "0px 0px green",
            backgroundColor: "green.600",
            transform: "translateY(-5px)",
          }}
        >
          Nastavi
          <span>
            <ArrowDownIcon />
          </span>
        </Button>
      </Flex>
    </ABox>
  );
};

export default KontaktPodaci;
