import React from "react";
import NumberFormat from "react-number-format";
import {
  Box,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Input,
  RadioGroup,
  Stack,
  Radio,
  Button,
} from "@chakra-ui/core";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { animated } from "react-spring";
import { Error } from "../style";
import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import CustomDate from "../CustomDate";

const ABox = animated(Box);

const InkubatorPodaci = (props) => {
  const {
    style,
    state,
    control,
    errors,
    handleSubmit,
    handleNext,
    handleBack,
    getValues,
  } = props;
  return (
    <ABox style={style} pos="absolute" top={0}>
      <Flex flexWrap="wrap" mb={{ base: "2em", md: 0 }}>
        <Heading w="100%" mt="1em">
          Inkubator
        </Heading>
        <Flex
          justifyContent="space-around"
          width="100%"
          flexWrap="wrap"
          mb={{ base: "3em", md: 0 }}
        >
          <Box mt="1em" h={{ base: "165px", md: "85px" }}>
            <FormControl isRequired>
              <FormLabel
                color="black"
                htmlFor="lokacijaInkubatora"
                fontSize="1.2em"
              >
                Lokacija poduzetničkog inkubatora
              </FormLabel>
              <Controller
                as={
                  <RadioGroup>
                    <Stack direction={{ base: "column", md: "row" }}>
                      <Radio
                        colorScheme="green"
                        size="lg"
                        mr="1em"
                        value="Orahovica"
                      >
                        Orahovica
                      </Radio>
                      <Radio
                        colorScheme="green"
                        size="lg"
                        mr="1em"
                        value="Pitomača"
                      >
                        Pitomača
                      </Radio>
                      <Radio
                        colorScheme="green"
                        size="lg"
                        mr="1em"
                        value="Slatina"
                      >
                        Slatina
                      </Radio>
                      <Radio
                        colorScheme="green"
                        size="lg"
                        mr="1em"
                        value="Virovitica"
                      >
                        Virovitica
                      </Radio>
                    </Stack>
                  </RadioGroup>
                }
                borderColor={
                  errors.hasOwnProperty("lokacijaInkubatora") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="lokacijaInkubatora"
                defaultValue={state.data.lokacijaInkubatora}
                rules={{
                  required: "Lokacija poduzetničkog inkubatora je obvezna",
                }}
              />
            </FormControl>
            <ErrorMessage
              as={Error}
              errors={errors}
              name="lokacijaInkubatora"
            />
          </Box>
          <Box
            mt={{ base: "2em", md: "1em" }}
            h="85px"
            w={{ base: "100%", md: 1 / 2 }}
            px="15px"
          >
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="ocekivaniDan">
                Očekivani dan ulaska u Inkubator
              </FormLabel>
              <Controller
                as={<CustomDate autoOk={true} />}
                borderColor={
                  errors.hasOwnProperty("ocekivaniDan") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="ocekivaniDan"
                placeholder="Očekivani dan ulaska u Inkubator"
                defaultValue={state.data.ocekivaniDan}
                rules={{ required: "Očekivani dan je obvezan" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="ocekivaniDan" />
          </Box>

          <Box mt="1em" h="85px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="oznakaProstora">
                Oznaka prostora (iz poziva)
              </FormLabel>
              <Controller
                as={Input}
                borderColor={
                  errors.hasOwnProperty("oznakaProstora") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="oznakaProstora"
                placeholder="Oznaka prostora"
                defaultValue={state.data.oznakaProstora}
                rules={{ required: "Oznaka prostora je obvezna" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="oznakaProstora" />
          </Box>
          <Box mt="1em" h="115px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="velicinaProstora" maxW="190px">
                Potrebna veličina prostora (m²)
              </FormLabel>
              <Controller
                as={
                  <NumberFormat
                    allowLeadingZeros
                    decimalSeparator={false}
                    type="text"
                    customInput={Input}
                  ></NumberFormat>
                }
                borderColor={
                  errors.hasOwnProperty("velicinaProstora") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="velicinaProstora"
                placeholder="Potrebna veličina prostora (m²)"
                defaultValue={state.data.velicinaProstora}
                rules={{ required: "Veličina prostora je obvezna" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="velicinaProstora" />
          </Box>
          <Box
            mt={{ base: "2.5em", md: "1em" }}
            h="115px"
            w={{ base: "100%", md: 1 / 2 }}
            px="15px"
          >
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="vrstaProstora" maxW="220px">
                Vrsta prostora (uredski/proizvodni/skladišni)
              </FormLabel>
              <Controller
                as={Input}
                borderColor={
                  errors.hasOwnProperty("vrstaProstora") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="vrstaProstora"
                placeholder="Vrsta prostora (uredski/proizvodni/skladišni)"
                defaultValue={state.data.vrstaProstora}
                rules={{ required: "Vrsta prostora je obvezna" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="vrstaProstora" />
          </Box>
          <Box mt="1em" w="100%">
            <Heading fontSize="1em" mb="0.5em">
              Trenutni broj zaposlenih
            </Heading>
            <Flex justifyContent="space-around" flexWrap="wrap">
              <Box w={{ base: "100%", md: 1 / 2 }} px="15px" h="85px" mt="1em">
                <FormControl isRequired>
                  <FormLabel color="black" htmlFor="tZaposleniStalno">
                    Stalno
                  </FormLabel>
                  <Controller
                    as={
                      <NumberFormat
                        allowLeadingZeros
                        decimalSeparator={false}
                        type="text"
                        customInput={Input}
                      ></NumberFormat>
                    }
                    borderColor={
                      errors.hasOwnProperty("tZaposleniStalno") ? "red.500" : ""
                    }
                    control={control}
                    color="black"
                    name="tZaposleniStalno"
                    placeholder="Broj stalno zaposlenih"
                    defaultValue={state.data.tZaposleniStalno}
                    rules={{ required: "Obvezno polje" }}
                  />
                </FormControl>
                <ErrorMessage
                  as={Error}
                  errors={errors}
                  name="tZaposleniStalno"
                />
              </Box>
              <Box w={{ base: "100%", md: 1 / 2 }} px="15px" h="85px" mt="1em">
                <FormControl isRequired>
                  <FormLabel color="black" htmlFor="tZaposleniHonorarno">
                    Honorarno
                  </FormLabel>
                  <Controller
                    as={
                      <NumberFormat
                        allowLeadingZeros
                        decimalSeparator={false}
                        type="text"
                        customInput={Input}
                      ></NumberFormat>
                    }
                    borderColor={
                      errors.hasOwnProperty("tZaposleniHonorarno")
                        ? "red.500"
                        : ""
                    }
                    control={control}
                    color="black"
                    name="tZaposleniHonorarno"
                    placeholder="Broj honorarno zaposlenih"
                    defaultValue={state.data.tZaposleniHonorarno}
                    rules={{ required: "Obvezno polje" }}
                  />
                </FormControl>
                <ErrorMessage
                  as={Error}
                  errors={errors}
                  name="tZaposleniHonorarno"
                />
              </Box>
            </Flex>
          </Box>

          <Box mt="1em" w="100%">
            <Heading fontSize="1em" mb="0.5em">
              Planirani broj zaposlenih nakon 1. godine
            </Heading>
            <Flex justifyContent="space-around" flexWrap="wrap">
              <Box w={{ base: "100%", md: 1 / 2 }} px="15px" h="85px" mt="1em">
                <FormControl isRequired>
                  <FormLabel color="black" htmlFor="p1ZaposleniStalno">
                    Stalno
                  </FormLabel>
                  <Controller
                    as={
                      <NumberFormat
                        allowLeadingZeros
                        decimalSeparator={false}
                        type="text"
                        customInput={Input}
                      ></NumberFormat>
                    }
                    borderColor={
                      errors.hasOwnProperty("p1ZaposleniStalno")
                        ? "red.500"
                        : ""
                    }
                    control={control}
                    color="black"
                    name="p1ZaposleniStalno"
                    placeholder="Broj stalno zaposlenih"
                    defaultValue={state.data.p1ZaposleniStalno}
                    rules={{ required: "Obvezno polje" }}
                  />
                </FormControl>
                <ErrorMessage
                  as={Error}
                  errors={errors}
                  name="p1ZaposleniStalno"
                />
              </Box>
              <Box w={{ base: "100%", md: 1 / 2 }} px="15px" h="85px" mt="1em">
                <FormControl isRequired>
                  <FormLabel color="black" htmlFor="p1ZaposleniHonorarno">
                    Honorarno
                  </FormLabel>
                  <Controller
                    as={
                      <NumberFormat
                        allowLeadingZeros
                        decimalSeparator={false}
                        type="text"
                        customInput={Input}
                      ></NumberFormat>
                    }
                    borderColor={
                      errors.hasOwnProperty("p1ZaposleniHonorarno")
                        ? "red.500"
                        : ""
                    }
                    control={control}
                    color="black"
                    name="p1ZaposleniHonorarno"
                    placeholder="Broj honorarno zaposlenih"
                    defaultValue={state.data.p1ZaposleniHonorarno}
                    rules={{ required: "Obvezno polje" }}
                  />
                </FormControl>
                <ErrorMessage
                  as={Error}
                  errors={errors}
                  name="p1ZaposleniHonorarno"
                />
              </Box>
            </Flex>
          </Box>
          <Box mt="1em" w="100%">
            <Heading fontSize="1em" mb="0.5em">
              Planirani broj zaposlenih nakon 2. godine
            </Heading>
            <Flex justifyContent="space-around" flexWrap="wrap">
              <Box w={{ base: "100%", md: 1 / 2 }} px="15px" h="85px" mt="1em">
                <FormControl isRequired>
                  <FormLabel color="black" htmlFor="p2ZaposleniStalno">
                    Stalno
                  </FormLabel>
                  <Controller
                    as={
                      <NumberFormat
                        allowLeadingZeros
                        decimalSeparator={false}
                        type="text"
                        customInput={Input}
                      ></NumberFormat>
                    }
                    borderColor={
                      errors.hasOwnProperty("p2ZaposleniStalno")
                        ? "red.500"
                        : ""
                    }
                    control={control}
                    color="black"
                    name="p2ZaposleniStalno"
                    placeholder="Broj stalno zaposlenih"
                    defaultValue={state.data.p2ZaposleniStalno}
                    rules={{ required: "Obvezno polje" }}
                  />
                </FormControl>
                <ErrorMessage
                  as={Error}
                  errors={errors}
                  name="p2ZaposleniStalno"
                />
              </Box>
              <Box w={{ base: "100%", md: 1 / 2 }} px="15px" h="85px" mt="1em">
                <FormControl isRequired>
                  <FormLabel color="black" htmlFor="p2ZaposleniHonorarno">
                    Honorarno
                  </FormLabel>
                  <Controller
                    as={
                      <NumberFormat
                        allowLeadingZeros
                        decimalSeparator={false}
                        type="text"
                        customInput={Input}
                      ></NumberFormat>
                    }
                    borderColor={
                      errors.hasOwnProperty("p2ZaposleniHonorarno")
                        ? "red.500"
                        : ""
                    }
                    control={control}
                    color="black"
                    name="p2ZaposleniHonorarno"
                    placeholder="Broj honorarno zaposlenih"
                    defaultValue={state.data.p2ZaposleniHonorarno}
                    rules={{ required: "Obvezno polje" }}
                  />
                </FormControl>
                <ErrorMessage
                  as={Error}
                  errors={errors}
                  name="p2ZaposleniHonorarno"
                />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <Flex
        d={{ base: "none", md: "flex" }}
        m="auto"
        mt="2em"
        maxW="600px"
        justifyContent="space-around"
        mb="2em"
      >
        <Button
          onClick={() => handleBack(getValues())}
          colorScheme="gray"
          boxShadow="-5px -5px gray"
          mr="15px"
          border="0"
          _hover={{
            boxShadow: "0px 0px gray",
            backgroundColor: "gray.600",
            transform: "translateY(-5px)",
            color: "white",
          }}
        >
          <span>
            <ArrowUpIcon />
          </span>
          Povratak
        </Button>
        <Button
          onClick={handleSubmit(handleNext)}
          colorScheme="green"
          boxShadow="-5px -5px green"
          border="0"
          _hover={{
            boxShadow: "0px 0px green",
            backgroundColor: "green.600",
            transform: "translateY(-5px)",
          }}
        >
          Nastavi
          <span>
            <ArrowDownIcon />
          </span>
        </Button>
      </Flex>
    </ABox>
  );
};

export default InkubatorPodaci;
