import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Textarea,
  Button,
  Stack,
  Checkbox,
  CheckboxGroup,
  Input,
  useToast,
} from "@chakra-ui/core";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { animated } from "react-spring";
import { Error } from "../style";
import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";

const ABox = animated(Box);

const Trziste = (props) => {
  const {
    style,
    state,
    control,
    errors,
    handleNext,
    handleSubmit,
    handleBack,
    getValues,
    setValue,
  } = props;
  const [reqs, setReqs] = useState({
    ostalo: false,
    mailingListe: false,
    ostaloReklamiranje: false,
  });
  const toast = useToast();
  return (
    <ABox style={style} pos="absolute" top={0}>
      <Flex flexWrap="wrap" mb={{ base: "3em", md: 0 }}>
        <Heading w="100%" mt="1em">
          TRŽIŠTE/TRŽIŠNA OPRAVDANOST
        </Heading>
        <Flex justifyContent="space-around" width="100%" flexWrap="wrap">
          <Box mt="1em" h={{ base: "210px", md: "165px" }} w="100%" px="10px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="potencijalniKlijenti">
                Tko su Vaši potencijalni korisnici, klijenti? Objasnite tko će
                kupovati vašu robu ili usluge. Imate li kakvih naznaka o
                količini posla ili njihovim navikama?
              </FormLabel>
              <Controller
                as={Textarea}
                borderColor={
                  errors.hasOwnProperty("potencijalniKlijenti") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="potencijalniKlijenti"
                placeholder=""
                defaultValue={state.data.potencijalniKlijenti}
                rules={{ required: "Polje je obvezno" }}
              />
            </FormControl>
            <ErrorMessage
              as={Error}
              errors={errors}
              name="potencijalniKlijenti"
            />
          </Box>
          <Box mt="1em" h={{ base: "230px", md: "180px" }} w="100%" px="10px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="konkurencija">
                Tko je Vaša konkurencija? Podaci o Vašim glavnim konkurentima,
                njihovim prednostima i slabostima u odnosu na Vas. (Pojasnite na
                koji ste način proveli istraživanje tržišta i koji su bili
                rezultati.)
              </FormLabel>
              <Controller
                as={Textarea}
                borderColor={
                  errors.hasOwnProperty("konkurencija") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="konkurencija"
                placeholder=""
                defaultValue={state.data.konkurencija}
                rules={{ required: "Polje je obvezno" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="konkurencija" />
          </Box>
          <Box mt="1em" h={{ base: "210px", md: "185px" }} w="100%" px="10px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="nadvladatiKonkurenciju">
                Kako ćete nadvladati svoju konkurenciju? Iznesite podatke o
                specijalnim karakteristikama ili uslugama koje ćete pružati.
                Zašto će ljudi radije kupovati od Vas nego od konkurencije?
              </FormLabel>
              <Controller
                as={Textarea}
                borderColor={
                  errors.hasOwnProperty("nadvladatiKonkurenciju")
                    ? "red.500"
                    : ""
                }
                control={control}
                color="black"
                name="nadvladatiKonkurenciju"
                placeholder=""
                defaultValue={state.data.nadvladatiKonkurenciju}
                rules={{ required: "Polje je obvezno" }}
              />
            </FormControl>
            <ErrorMessage
              as={Error}
              errors={errors}
              name="nadvladatiKonkurenciju"
            />
          </Box>
          <Box mt="1em" h={{ base: "585px", md: "530px" }} w="100%" px="10px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="mjestaProdavanja">
                Kako i gdje ćete prodavati svoje proizvode/usluge?
              </FormLabel>

              <Stack>
                <Controller
                  control={control}
                  name="prodavanjeUsluga"
                  defaultValue={state.data.prodavanjeUsluga}
                  rules={{ required: "Polje je obvezno", minLength: 1 }}
                  render={({ onChange, value, name }) => (
                    <CheckboxGroup
                      name={name}
                      value={value}
                      onChange={(values) => {
                        if (values.includes("ostalo")) {
                          setReqs({ ...reqs, ostalo: true });
                        } else {
                          setValue("ostaloOpis", "");
                          setReqs({ ...reqs, ostalo: false });
                        }
                        onChange(values);
                      }}
                    >
                      <Checkbox size="md" colorScheme="green" value="ducan">
                        Maloprodajni dućan
                      </Checkbox>

                      <Checkbox size="md" colorScheme="green" value="stand">
                        Štand (npr. na tržnici)
                      </Checkbox>

                      <Checkbox
                        size="md"
                        colorScheme="green"
                        value="kucnaRadinost"
                      >
                        Kućna radinost
                      </Checkbox>

                      <Checkbox
                        size="md"
                        colorScheme="green"
                        value="vlastitiProstor"
                      >
                        Vlastiti salon/ radiona
                      </Checkbox>

                      <Checkbox
                        size="md"
                        colorScheme="green"
                        value="prodajaTudi"
                      >
                        Vlastita prodaju u tuđem dućanu
                      </Checkbox>

                      <Checkbox
                        size="md"
                        colorScheme="green"
                        value="veleprodaja"
                      >
                        Veleprodaja
                      </Checkbox>

                      <Checkbox
                        size="md"
                        value="direktnaProdaja"
                        colorScheme="green"
                        textAlign="left"
                      >
                        Direktna prodaja (trgovanje i predstavljanje proizvoda i
                        usluga izravno kupcima, licem u lice)
                      </Checkbox>

                      <Checkbox
                        size="md"
                        colorScheme="green"
                        value="teleprodaja"
                      >
                        Teleprodaja
                      </Checkbox>

                      <Checkbox
                        size="md"
                        colorScheme="green"
                        value="webProdaja"
                      >
                        Web prodaja/ Internet prodaja
                      </Checkbox>

                      <Checkbox size="md" colorScheme="green" value="sajmovi">
                        Sajmovi
                      </Checkbox>

                      <Checkbox
                        size="md"
                        colorScheme="green"
                        value="prodajniCentar"
                      >
                        Prodajni/trgovački centar
                      </Checkbox>

                      <Checkbox
                        size="md"
                        colorScheme="green"
                        value="prodajniAutomati"
                      >
                        Prodajni automati
                      </Checkbox>

                      <Box mt="1em" h="100px" w={1 / 2} textAlign="left">
                        <FormControl isRequired>
                          <Checkbox
                            size="md"
                            colorScheme="green"
                            value="ostalo"
                          >
                            Ostalo (navesti)
                          </Checkbox>

                          <Controller
                            mt="0.5em"
                            as={Input}
                            borderColor={
                              errors.hasOwnProperty("ostaloOpis")
                                ? "red.500"
                                : ""
                            }
                            control={control}
                            color="black"
                            name="ostaloOpis"
                            placeholder="Ostalo"
                            defaultValue={state.data.ostaloOpis}
                            rules={{
                              required: reqs.ostalo ? "Obvezno polje" : false,
                            }}
                          />
                        </FormControl>
                        <ErrorMessage
                          as={Error}
                          errors={errors}
                          name="ostaloOpis"
                        />
                      </Box>
                    </CheckboxGroup>
                  )}
                />
              </Stack>
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="mjestaProdavanja" />
          </Box>
          <Box mt="1em" h={{ base: "675px", md: "600px" }} w="100%" px="10px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="mjestaProdavanja">
                Na koji način ćete obavještavati klijete/korisnike/kupce o Vašoj
                inicijativi? (reklamiranje):
              </FormLabel>

              <Stack>
                <Controller
                  control={control}
                  name="naciniObavjestavanja"
                  defaultValue={state.data.naciniObavjestavanja}
                  rules={{ required: "Polje je obvezno", minLength: 1 }}
                  render={({ onChange, value, name }) => (
                    <CheckboxGroup
                      name={name}
                      value={value}
                      onChange={(values) => {
                        if (values.includes("ostaloReklamiranje")) {
                          setReqs((reqs) => ({
                            ...reqs,
                            ostaloReklamiranje: true,
                          }));
                        } else {
                          setReqs((reqs) => ({
                            ...reqs,
                            ostaloReklamiranje: false,
                          }));
                          setValue("ostaloReklamiranjeOpis", "");
                        }
                        if (values.includes("mailingListe")) {
                          setReqs((reqs) => ({
                            ...reqs,
                            mailingListe: true,
                          }));
                        } else {
                          setReqs((reqs) => ({
                            ...reqs,
                            mailingListe: false,
                          }));
                          setValue("mailingListeOpis", "");
                        }
                        onChange(values);
                      }}
                    >
                      <Checkbox
                        size="md"
                        colorScheme="green"
                        value="usmenaReklama"
                      >
                        Usmena reklama
                      </Checkbox>

                      <Checkbox
                        size="md"
                        colorScheme="green"
                        value="posjetnice"
                      >
                        Posjetnice
                      </Checkbox>

                      <Checkbox
                        size="md"
                        colorScheme="green"
                        value="novinskiUmeci"
                      >
                        Novinski umeci
                      </Checkbox>

                      <Checkbox size="md" colorScheme="green" value="plakati">
                        Plakati/ letci
                      </Checkbox>

                      <Checkbox size="md" colorScheme="green" value="prospekti">
                        Prospekti
                      </Checkbox>

                      <Checkbox
                        size="md"
                        colorScheme="green"
                        value="prozoriDucana"
                      >
                        Prozori dućana
                      </Checkbox>

                      <Checkbox
                        value="posta"
                        size="md"
                        colorScheme="green"
                        textAlign="left"
                      >
                        Pošta
                      </Checkbox>

                      <Checkbox
                        size="md"
                        colorScheme="green"
                        value="placeniOglasi"
                      >
                        Plaćeni oglasi u novinama
                      </Checkbox>

                      <Checkbox
                        size="md"
                        colorScheme="green"
                        value="vozilaNatpisi"
                      >
                        Natpisi na vozilima
                      </Checkbox>

                      <Checkbox
                        size="md"
                        colorScheme="green"
                        value="telemarketing"
                      >
                        Telemarketing
                      </Checkbox>

                      <Checkbox
                        size="md"
                        colorScheme="green"
                        value="radioReklame"
                      >
                        Reklame na radiju
                      </Checkbox>

                      <Checkbox size="md" colorScheme="green" value="tvReklame">
                        Reklame na televiziji
                      </Checkbox>

                      <Box mt="1em" h="100px" w={1 / 2} textAlign="left">
                        <FormControl isRequired>
                          <Checkbox
                            size="md"
                            colorScheme="green"
                            value="mailingListe"
                          >
                            Mailing liste
                          </Checkbox>

                          <Controller
                            mt="0.5em"
                            as={Input}
                            borderColor={
                              errors.hasOwnProperty("mailingListeOpis")
                                ? "red.500"
                                : ""
                            }
                            control={control}
                            color="black"
                            name="mailingListeOpis"
                            placeholder="Mailing liste"
                            defaultValue={state.data.mailingListeOpis}
                            rules={{
                              required: reqs.mailingListe
                                ? "Mailing lista je obavezna"
                                : false,
                            }}
                          />
                        </FormControl>
                        <ErrorMessage
                          as={Error}
                          errors={errors}
                          name="mailingListeOpis"
                        />
                      </Box>
                      <Box mt="1em" h="100px" w={1 / 2} textAlign="left">
                        <FormControl isRequired>
                          <Checkbox
                            size="md"
                            colorScheme="green"
                            value="ostaloReklamiranje"
                          >
                            Ostalo
                          </Checkbox>

                          <Controller
                            mt="0.5em"
                            as={Input}
                            borderColor={
                              errors.hasOwnProperty("ostaloReklamiranjeOpis")
                                ? "red.500"
                                : ""
                            }
                            control={control}
                            color="black"
                            name="ostaloReklamiranjeOpis"
                            placeholder="Ostalo"
                            defaultValue={state.data.ostaloReklamiranjeOpis}
                            rules={{
                              required: reqs.ostaloReklamiranje
                                ? "Obvezno polje"
                                : false,
                            }}
                          />
                        </FormControl>
                        <ErrorMessage
                          as={Error}
                          errors={errors}
                          name="ostaloReklamiranjeOpis"
                        />
                      </Box>
                    </CheckboxGroup>
                  )}
                />
              </Stack>
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="mjestaProdavanja" />
          </Box>
        </Flex>
      </Flex>
      <Flex
        d={{ base: "none", md: "flex" }}
        m="auto"
        mt="4em"
        maxW="600px"
        justifyContent="space-around"
        mb="2em"
      >
        <Button
          onClick={() => handleBack(getValues())}
          colorScheme="gray"
          boxShadow="-5px -5px gray"
          mr="15px"
          border="0"
          _hover={{
            boxShadow: "0px 0px gray",
            backgroundColor: "gray.600",
            transform: "translateY(-5px)",
            color: "white",
          }}
        >
          <span>
            <ArrowUpIcon />
          </span>
          Povratak
        </Button>
        <Button
          onClick={handleSubmit((data) => {
            if (data.prodavanjeUsluga.length < 1) {
              toast({
                title: "Greška",
                description:
                  "Odaberite najmanje 1 vrijednost u polju: 'Kako i gdje ćete prodavati svoje proizvode/usluge?' ",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            } else if (data.naciniObavjestavanja.length < 1) {
              toast({
                title: "Greška",
                description:
                  "Odaberite najmanje 1 vrijednost u polju: 'Na koji način ćete obavještavati klijete/korisnike/kupce o Vašoj inicijativi? (reklamiranje):' ",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            } else {
              handleNext(data);
            }
          })}
          colorScheme="green"
          boxShadow="-5px -5px green"
          border="0"
          _hover={{
            boxShadow: "0px 0px green",
            backgroundColor: "green.600",
            transform: "translateY(-5px)",
          }}
        >
          Nastavi
          <span>
            <ArrowDownIcon />
          </span>
        </Button>
      </Flex>
    </ABox>
  );
};

export default Trziste;
