import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { StateMachineProvider, createStore } from "little-state-machine";
import Main from "../Main/Main";
import Zahtjev from "../Zahtjev/Zahtjev";
import PoslovniPlan from "../PoslovniPlan/PoslovniPlan";
import Izjava from "../Izjava/Izjava";
import ScrollToTop from "../../hooks/index";

import "./App.css";

createStore({
  data: {
    imePrezime: "",
    adresa: "",
    telMob: "",
    email: "",
    nazivTvrtke: "",
    oibTvrtke: "",
    adresaTvrtke: "",
    IBAN: "",
    datumRegistracije: new Date(),
    emailTvrtke: "",
    fax: "",
    glavnaDjelatnost: "",
    komentar: "",
    lokacijaInkubatora: "",
    naziv: "",
    ocekivaniDan: new Date(),
    odgovornaOsoba: "",
    oznakaProstora: "",
    p1ZaposleniHonorarno: "",
    p1ZaposleniStalno: "",
    p2ZaposleniHonorarno: "",
    p2ZaposleniStalno: "",
    posebniUvjeti: "",
    posebniZahtjevi: "",
    potrebeUsluga: "",
    pravniOblik: "",
    tZaposleniHonorarno: "",
    tZaposleniStalno: "",
    telefon: "",
    velicinaProstora: "",
    vlasnici: "",
    vrstaProstora: "",
    webAdresa: "",
    potencijalniKlijenti: "",
    konkurencija: "",
    nadvladatiKonkurenciju: "",
    uslugeProizvodi: "",
    perspektive: "",
    znanjeIskustvo: "",
    mailingListeOpis: "",
    ostaloReklamiranjeOpis: "",
    naciniObavjestavanja: [],
    prodavanjeUsluga: [],
  },
});

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <div>
          <StateMachineProvider>
            <Switch>
              <Route path="/zahtjev">
                <Zahtjev />
              </Route>
              <Route path="/plan">
                <PoslovniPlan />
              </Route>
              <Route path="/izjava">
                <Izjava />
              </Route>

              <Route exact path="/">
                <Main />
              </Route>
            </Switch>
          </StateMachineProvider>
        </div>
      </Router>
    </div>
  );
}

export default App;
