import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Flex, Heading, Button } from "@chakra-ui/core";
import { useForm } from "react-hook-form";
import { /* useSpring, */ useTransition } from "react-spring";
import { useStateMachine } from "little-state-machine";
import FirstPage from "./FirstPage";
import LastStep from "./LastStep";
import { withStyles } from "@material-ui/core/styles";
import updateAction from "./updateAction";
import MobileStepper from "@material-ui/core/MobileStepper";
import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";

const CustomStepper = withStyles({
  root: {
    "& div": {
      "& .MuiMobileStepper-dotActive": {
        backgroundColor: "#38A169",
      },
    },
  },
})(MobileStepper);

const pages = [
  {
    title: "Kontakt podaci",
    content: ({
      style,
      handleNext,
      handleBack,
      state,
      control,
      errors,
      handleSubmit,
      getValues,
    }) => (
      <FirstPage
        style={style}
        handleNext={handleNext}
        handleBack={handleBack}
        getValues={getValues}
        state={state}
        control={control}
        handleSubmit={handleSubmit}
        errors={errors}
      />
    ),
  },

  {
    title: "Završeno",
    content: ({ style, state, handleSubmit, handleBack }) => (
      <LastStep
        style={style}
        handleBack={handleBack}
        state={state}
        handleSubmit={handleSubmit}
      />
    ),
  },
];
const pageHeights = {
  0: "300px",
};

const Izjava = () => {
  let history = useHistory();

  const [current, setCurrent] = useState(0);
  const loadRef = useRef(null);
  const isPrevious = useRef(false);
  const transitions = useTransition(current, (item) => item, {
    from: {
      opacity: 0,
      transform: `translateY(${isPrevious.current ? "100%" : "-100%"}) `,
      transition: "all 0.1s ease",
    },
    enter: {
      opacity: 1,
      transform: "translateY(0%) ",
      transition: "all 0.1s ease",
    },
    leave: {
      opacity: 0,
      transform: `translateY(${isPrevious.current ? "-100%" : "100%"}) `,
      transition: "all 0.1s ease",
    },
    immediate: !loadRef.current,
  });

  const { control, handleSubmit, errors, getValues } = useForm({
    shouldFocusError: true,
  });
  const { action, state } = useStateMachine(updateAction);

  const handleNext = (data) => {
    isPrevious.current = false;
    if (current === 4) {
      action(data);
      return setCurrent(current);
    } else {
      action(data);
      setCurrent((current) => current + 1);
    }
  };
  const handleBack = (data) => {
    isPrevious.current = true;
    if (current === 0) {
      return setCurrent(current);
    } else {
      action(data);
      setCurrent((current) => current - 1);
    }
  };
  const goTo = (location) => {
    history.push(location);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [current]);
  return (
    <div>
      <Heading>Izjava</Heading>
      <Flex
        maxW="840px"
        w="100%"
        mx="auto"
        mt="2em"
        alignSelf="flex-start"
        px={{ base: "2em", md: 0 }}
      >
        <Button
          onClick={() => {
            goTo("/");
          }}
          colorScheme="gray"
          boxShadow="-5px -5px gray"
          mr="15px"
          border="0"
          _hover={{
            boxShadow: "0px 0px gray",
            backgroundColor: "gray.600",
            transform: "translateY(-5px)",
            color: "white",
          }}
        >
          Početna
        </Button>
      </Flex>

      <Box pos="relative">
        <Flex
          ref={loadRef}
          p="20"
          pt="0"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          flexDir="column"
          position="relative"
          margin="auto"
          maxW="600px"
          w="100%"
          minH={pageHeights[current]}
        >
          {transitions.map(({ item, props, key }) => {
            if (pages[item]) {
              const Page = pages[item].content;
              return (
                <Page
                  key={key}
                  style={props}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  state={state}
                  control={control}
                  handleSubmit={handleSubmit}
                  errors={errors}
                  getValues={getValues}
                />
              );
            }
            return null;
          })}
        </Flex>
        <Box d={{ base: "block", md: "none" }}>
          <CustomStepper
            position="bottom"
            variant="dots"
            steps={2}
            activeStep={current}
            nextButton={
              <Button
                disabled={current > 2}
                size="sm"
                onClick={handleSubmit(handleNext)}
                colorScheme="green"
                border="0"
              >
                Nastavi
                <span>
                  <ArrowDownIcon />
                </span>
              </Button>
            }
            backButton={
              <Button
                disabled={current < 1}
                size="sm"
                onClick={() => handleBack(getValues())}
                colorScheme="gray"
                mr="15px"
                border="0"
              >
                <span>
                  <ArrowUpIcon />
                </span>
                Povratak
              </Button>
            }
          />
        </Box>
      </Box>
    </div>
  );
};

export default Izjava;
