import React from "react";
import { Box, Flex, Heading, Text } from "@chakra-ui/core";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "../../hooks/index";

const Main = () => {
  let history = useHistory();
  const size = useWindowSize();

  const goTo = (location) => {
    history.push(location);
  };

  return (
    <Flex
      maxW="500px"
      mx="auto"
      w="100%"
      justifyContent="center"
      mt="5em"
      flexWrap="wrap"
      px={{ base: "2em", md: "1em" }}
      mb="3em"
    >
      <Box
        h="170px"
        w="100%"
        cursor="pointer"
        p="20px"
        borderRadius="10px"
        border="2px solid #38A169"
        boxShadow="rgba(56, 161, 105, 0.4) -5px 5px, rgba(56, 161, 105, 0.3) -10px 10px, rgba(56, 161, 105, 0.2) -15px 15px;"
        transition="all 0.3s ease"
        _hover={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;",
          transition: "all 0.3s ease",
          transform: "scale(1.1)",
        }}
        onClick={() => {
          if (size.width > 900) {
            goTo("/zahtjev");
          } else {
            setTimeout(() => {
              goTo("/zahtjev");
            }, 300);
          }
        }}
      >
        <Heading>Zahtjev za prijem u inkubator</Heading>
        <Text mt="1em">Kreni</Text>
      </Box>
      <Box
        h="170px"
        mt="4em"
        w="100%"
        cursor="pointer"
        p="20px"
        borderRadius="10px"
        border="2px solid #38A169"
        boxShadow="rgba(56, 161, 105, 0.4) -5px 5px, rgba(56, 161, 105, 0.3) -10px 10px, rgba(56, 161, 105, 0.2) -15px 15px;"
        transition="all 0.3s ease"
        _hover={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;",
          transition: "all 0.3s ease",
          transform: "scale(1.1)",
        }}
        onClick={() => {
          if (size.width > 900) {
            goTo("/plan");
          } else {
            setTimeout(() => {
              goTo("/plan");
            }, 300);
          }
        }}
      >
        <Heading>Poslovni plan</Heading>
        <Text mt="1em">Kreni</Text>
      </Box>
      <Box
        h="170px"
        mt="4em"
        w="100%"
        cursor="pointer"
        p="20px"
        borderRadius="10px"
        border="2px solid #38A169"
        boxShadow="rgba(56, 161, 105, 0.4) -5px 5px, rgba(56, 161, 105, 0.3) -10px 10px, rgba(56, 161, 105, 0.2) -15px 15px;"
        transition="all 0.3s ease"
        _hover={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;",
          transition: "all 0.3s ease",
          transform: "scale(1.1)",
        }}
        onClick={() => {
          if (size.width > 900) {
            goTo("/izjava");
          } else {
            setTimeout(() => {
              goTo("/izjava");
            }, 300);
          }
        }}
      >
        <Heading>Izjava</Heading>
        <Text mt="1em">Kreni</Text>
      </Box>
    </Flex>
  );
};

export default Main;
