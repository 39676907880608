import React from "react";
import {
  Box,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Button,
  Textarea,
} from "@chakra-ui/core";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { animated } from "react-spring";
import { Error } from "../style";
import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";

const ABox = animated(Box);

const PosebniZahtjevi = (props) => {
  const {
    style,
    state,
    control,
    errors,
    handleSubmit,
    handleNext,
    handleBack,
    getValues,
  } = props;

  return (
    <ABox style={style} pos="absolute" top={0}>
      <Flex flexWrap="wrap">
        <Heading w="100%" mt="1em">
          Posebni zahtjevi i ostalo
        </Heading>
        <Flex
          justifyContent="space-around"
          width="100%"
          flexWrap="wrap"
          p={{ base: "2em", md: 0 }}
          mb={{ base: "3em", md: 0 }}
        >
          <Box mt="1em" w="100%" px="10px">
            <FormControl>
              <FormLabel color="black" htmlFor="posebniZahtjevi">
                Posebni zahtjevi (jačina struje, ventilacija, prilaz prostoru,
                visina prostora, temperatura,...)
              </FormLabel>
              <Controller
                as={Textarea}
                borderColor={
                  errors.hasOwnProperty("posebniZahtjevi") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="posebniZahtjevi"
                placeholder="Posebni zahtjevi"
                defaultValue={state.data.posebniZahtjevi}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="posebniZahtjevi" />
          </Box>
          <Box mt="1em" w="100%" px="10px">
            <FormControl>
              <FormLabel color="black" htmlFor="posebniUvjeti">
                Postoji li prilikom obavljanja Vaše djelatnosti: opasan otpad,
                velika buka, vibracija, otrovi, radijacija, toplina, opasnost od
                požara ili nešto neobično
              </FormLabel>
              <Controller
                as={Textarea}
                borderColor={
                  errors.hasOwnProperty("posebniUvjeti") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="posebniUvjeti"
                placeholder="Posebni uvjeti"
                defaultValue={state.data.posebniUvjeti}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="posebniUvjeti" />
          </Box>
          <Box mt="1em" w="100%" px="10px">
            <FormControl>
              <FormLabel color="black" htmlFor="potrebeUsluga">
                Navedite potrebe za uslugama Poduzetničkog inkubatora VPŽ d.o.o.
                tijekom boravka (operativne usluge, knjigovodstvo, savjetovanje,
                istraživanje tržišta, podrška poslovanju,...)
              </FormLabel>
              <Controller
                as={Textarea}
                borderColor={
                  errors.hasOwnProperty("potrebeUsluga") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="potrebeUsluga"
                placeholder="Navedite potrebe za uslugama..."
                defaultValue={state.data.potrebeUsluga}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="potrebeUsluga" />
          </Box>
          <Box mt="1em" w="100%" px="10px">
            <FormControl>
              <FormLabel color="black" htmlFor="komentar">
                Vaš komentar
              </FormLabel>
              <Controller
                as={Textarea}
                borderColor={errors.hasOwnProperty("komentar") ? "red.500" : ""}
                control={control}
                color="black"
                name="komentar"
                placeholder="Komentar"
                defaultValue={state.data.komentar}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="komentar" />
          </Box>
        </Flex>
      </Flex>
      <Flex
        d={{ base: "none", md: "flex" }}
        m="auto"
        mt="2em"
        maxW="600px"
        justifyContent="space-around"
        mb="2em"
      >
        <Button
          onClick={() => handleBack(getValues())}
          colorScheme="gray"
          boxShadow="-5px -5px gray"
          mr="15px"
          border="0"
          _hover={{
            boxShadow: "0px 0px gray",
            backgroundColor: "gray.600",
            transform: "translateY(-5px)",
            color: "white",
          }}
        >
          <span>
            <ArrowUpIcon />
          </span>
          Povratak
        </Button>
        <Button
          onClick={handleSubmit(handleNext)}
          colorScheme="green"
          boxShadow="-5px -5px green"
          border="0"
          _hover={{
            boxShadow: "0px 0px green",
            backgroundColor: "green.600",
            transform: "translateY(-5px)",
          }}
        >
          Završi
          <span>
            <ArrowDownIcon />
          </span>
        </Button>
      </Flex>
    </ABox>
  );
};

export default PosebniZahtjevi;
