import React from "react";
import {
  Box,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Textarea,
  Button,
} from "@chakra-ui/core";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { animated } from "react-spring";
import { Error } from "../style";
import { ArrowDownIcon } from "@chakra-ui/icons";

const ABox = animated(Box);

const Polaziste = (props) => {
  const { style, state, control, errors, handleNext, handleSubmit } = props;

  return (
    <ABox style={style} pos="absolute" top={0}>
      <Flex flexWrap="wrap" mb={{ base: "3em", md: 0 }}>
        <Heading w="100%" mt="1em">
          Polazište
        </Heading>
        <Flex justifyContent="space-around" width="100%" flexWrap="wrap">
          <Box mt={{ base: "2em", md: "1em" }} h="140px" w="100%" px="10px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="opisMotivacije">
                Opišite ukratko kako ste došli na ideju o pokretanju posla. Što
                Vas je motiviralo.
              </FormLabel>
              <Controller
                as={Textarea}
                borderColor={
                  errors.hasOwnProperty("opisMotivacije") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="opisMotivacije"
                placeholder="Motivacija"
                defaultValue={state.data.opisMotivacije}
                rules={{ required: "Polje je obvezno" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="opisMotivacije" />
          </Box>
          <Box
            mt={{ base: "2em", md: "1em" }}
            h={{ base: "200px", md: "140px" }}
            w="100%"
            px="10px"
          >
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="planRada">
                Objasnite svojim riječima što planirate napraviti odnosno koje
                korake ćete poduzeti u svrhu pokretanja posla.
              </FormLabel>
              <Controller
                as={Textarea}
                borderColor={errors.hasOwnProperty("planRada") ? "red.500" : ""}
                control={control}
                color="black"
                name="planRada"
                placeholder=""
                defaultValue={state.data.planRada}
                rules={{ required: "Polje je obvezno" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="planRada" />
          </Box>
        </Flex>
      </Flex>
      <Flex
        d={{ base: "none", md: "flex" }}
        m="auto"
        mt="4em"
        maxW="600px"
        justifyContent="space-around"
        mb="2em"
      >
        <Button
          onClick={handleSubmit(handleNext)}
          colorScheme="green"
          boxShadow="-5px -5px green"
          border="0"
          _hover={{
            boxShadow: "0px 0px green",
            backgroundColor: "green.600",
            transform: "translateY(-5px)",
          }}
        >
          Nastavi
          <span>
            <ArrowDownIcon />
          </span>
        </Button>
      </Flex>
    </ABox>
  );
};

export default Polaziste;
