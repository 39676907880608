import React from "react";
import {
  Box,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Textarea,
  Button,
} from "@chakra-ui/core";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { animated } from "react-spring";
import { Error } from "../style";
import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";

const ABox = animated(Box);

const OpisPoslovanja = (props) => {
  const {
    style,
    state,
    control,
    errors,
    handleNext,
    handleSubmit,
    handleBack,
    getValues,
  } = props;

  return (
    <ABox style={style} pos="absolute" top={0}>
      <Flex flexWrap="wrap" mb={{ base: "3em", md: 0 }}>
        <Heading w="100%" mt="1em">
          Opis poslovanja
        </Heading>
        <Flex justifyContent="space-around" width="100%" flexWrap="wrap">
          <Box mt="1em" h="165px" w="100%" px="10px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="uslugeProizvodi">
                Opišite što planirate proizvoditi i/ ili prodavati, odnosno
                usluge koje planirate pružati.
              </FormLabel>
              <Controller
                as={Textarea}
                borderColor={
                  errors.hasOwnProperty("uslugeProizvodi") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="uslugeProizvodi"
                placeholder=""
                defaultValue={state.data.uslugeProizvodi}
                rules={{ required: "Polje je obvezno" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="uslugeProizvodi" />
          </Box>
          <Box mt="1em" h={{ base: "210px", md: "180px" }} w="100%" px="10px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="znanjeIskustvo">
                Objasnite svojim riječima zašto smatrate da Vi (i Vaši poslovni
                partneri) imate znanje i iskustvo, sposobnost i predanost da
                predloženu poslovnu inicijativu učinite uspješnom.
              </FormLabel>
              <Controller
                as={Textarea}
                borderColor={
                  errors.hasOwnProperty("znanjeIskustvo") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="znanjeIskustvo"
                placeholder=""
                defaultValue={state.data.znanjeIskustvo}
                rules={{ required: "Polje je obvezno" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="znanjeIskustvo" />
          </Box>
          <Box mt="1em" h={{ base: "165px", md: "140px" }} w="100%" px="10px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="perspektive">
                Po Vašem mišljenju koje su perspektive Vašeg poslovnog subjekta
                (novo zapošljavanje, širenje poslovanja i sl.)
              </FormLabel>
              <Controller
                as={Textarea}
                borderColor={
                  errors.hasOwnProperty("perspektive") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="perspektive"
                placeholder=""
                defaultValue={state.data.perspektive}
                rules={{ required: "Polje je obvezno" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="perspektive" />
          </Box>
        </Flex>
      </Flex>
      <Flex
        d={{ base: "none", md: "flex" }}
        m="auto"
        mt="4em"
        maxW="600px"
        justifyContent="space-around"
        mb="2em"
      >
        <Button
          onClick={() => handleBack(getValues())}
          colorScheme="gray"
          boxShadow="-5px -5px gray"
          mr="15px"
          border="0"
          _hover={{
            boxShadow: "0px 0px gray",
            backgroundColor: "gray.600",
            transform: "translateY(-5px)",
            color: "white",
          }}
        >
          <span>
            <ArrowUpIcon />
          </span>
          Povratak
        </Button>
        <Button
          onClick={handleSubmit(handleNext)}
          colorScheme="green"
          boxShadow="-5px -5px green"
          border="0"
          _hover={{
            boxShadow: "0px 0px green",
            backgroundColor: "green.600",
            transform: "translateY(-5px)",
          }}
        >
          Nastavi
          <span>
            <ArrowDownIcon />
          </span>
        </Button>
      </Flex>
    </ABox>
  );
};

export default OpisPoslovanja;
