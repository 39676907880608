import React from "react";
import NumberFormat from "react-number-format";
import {
  Box,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
} from "@chakra-ui/core";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { animated } from "react-spring";
import { Error } from "../style";
import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import { emailRegEx } from "../../functions/index";
import CustomDate from "../CustomDate";

const ABox = animated(Box);

const PodaciTvrtke = (props) => {
  const {
    style,
    state,
    control,
    errors,
    handleSubmit,
    handleNext,
    handleBack,
    getValues,
    register,
  } = props;

  return (
    <ABox style={style} pos="absolute" top={0} mb="5em">
      <Flex flexWrap="wrap">
        <Heading fontSize="2em" w="100%" mt="1em">
          Podaci tvrtke
        </Heading>
        <Flex justifyContent="space-around" width="100%" flexWrap="wrap">
          <Box mt="1em" h="85px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="naziv">
                Naziv tvrtke
              </FormLabel>
              <Controller
                as={<Input inputElementRef={register} />}
                borderColor={errors.hasOwnProperty("naziv") ? "red.500" : ""}
                control={control}
                color="black"
                name="naziv"
                placeholder="Naziv tvrtke"
                defaultValue={state.data.naziv}
                rules={{ required: "Naziv tvrtke je obvezan" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="naziv" />
          </Box>
          <Box mt="1em" h="85px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="IBAN">
                IBAN
              </FormLabel>
              <Controller
                as={<Input inputElementRef={register} />}
                borderColor={errors.hasOwnProperty("IBAN") ? "red.500" : ""}
                control={control}
                color="black"
                name="IBAN"
                placeholder="IBAN"
                defaultValue={state.data.IBAN}
                rules={{
                  required: "IBAN je obvezan",
                  minLength: {
                    value: 21,
                    message: "IBAN mora sadržavati 21 znak",
                  },
                  maxLength: {
                    value: 21,
                    message: "IBAN mora sadržavati 21 znak",
                  },
                }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="IBAN" />
          </Box>
          <Box mt="1em" h="85px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="adresaTvrtke">
                Adresa
              </FormLabel>
              <Controller
                as={<Input inputElementRef={register} />}
                borderColor={
                  errors.hasOwnProperty("adresaTvrtke") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="adresaTvrtke"
                placeholder="Adresa"
                defaultValue={state.data.adresaTvrtke}
                rules={{ required: "Adresa je obvezna" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="adresaTvrtke" />
          </Box>
          <Box mt="1em" h="85px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="telefon">
                Telefon
              </FormLabel>
              <Controller
                as={
                  <NumberFormat
                    allowLeadingZeros
                    decimalSeparator={false}
                    type="text"
                    customInput={Input}
                  ></NumberFormat>
                }
                borderColor={errors.hasOwnProperty("telefon") ? "red.500" : ""}
                control={control}
                color="black"
                name="telefon"
                placeholder="Telefon"
                defaultValue={state.data.telefon}
                rules={{ required: "Telefon je obvezan" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="telefon" />
          </Box>
          <Box mt="1em" h="85px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl>
              <FormLabel color="black" htmlFor="fax">
                Fax
              </FormLabel>
              <Controller
                as={
                  <NumberFormat
                    allowLeadingZeros
                    decimalSeparator={false}
                    type="text"
                    customInput={Input}
                  ></NumberFormat>
                }
                borderColor={errors.hasOwnProperty("fax") ? "red.500" : ""}
                control={control}
                color="black"
                name="fax"
                placeholder="Fax"
                defaultValue={state.data.fax}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="fax" />
          </Box>
          <Box mt="1em" h="85px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="emailTvrtke">
                E-mail
              </FormLabel>
              <Controller
                as={<Input />}
                borderColor={
                  errors.hasOwnProperty("emailTvrtke") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="emailTvrtke"
                placeholder="E-mail"
                defaultValue={state.data.emailTvrtke}
                rules={{
                  required: "E-mail je obvezan",
                  pattern: {
                    value: emailRegEx,
                    message: "Email adresa nije ispravna",
                  },
                }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="emailTvrtke" />
          </Box>

          <Box mt="1em" h="85px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl>
              <FormLabel color="black" htmlFor="webAdresa">
                Web adresa
              </FormLabel>
              <Controller
                as={<Input inputElementRef={register} />}
                borderColor={
                  errors.hasOwnProperty("webAdresa") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="webAdresa"
                placeholder="Web adresa"
                defaultValue={state.data.webAdresa}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="webAdresa" />
          </Box>
          <Box mt="1em" h="85px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="pravniOblik">
                Pravni oblik
              </FormLabel>
              <Controller
                as={<Input inputElementRef={register} />}
                borderColor={
                  errors.hasOwnProperty("pravniOblik") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="pravniOblik"
                placeholder="Pravni oblik"
                defaultValue={state.data.pravniOblik}
                rules={{ required: "Pravni oblik je obvezan" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="pravniOblik" />
          </Box>
          <Box mt="1em" h="85px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="vlasnici">
                Vlasnik/ci
              </FormLabel>
              <Controller
                as={<Input inputElementRef={register} />}
                borderColor={errors.hasOwnProperty("vlasnici") ? "red.500" : ""}
                control={control}
                color="black"
                name="vlasnici"
                placeholder="Vlasnik/ci"
                defaultValue={state.data.vlasnici}
                rules={{ required: "Vlasnik/ci je obvezan" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="vlasnici" />
          </Box>
          <Box mt="1em" h="85px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="odgovornaOsoba">
                Odgovorna osoba/e
              </FormLabel>
              <Controller
                as={<Input inputElementRef={register} />}
                borderColor={
                  errors.hasOwnProperty("odgovornaOsoba") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="odgovornaOsoba"
                placeholder="Odgovorna osoba/e (direktor/vlasnik)"
                defaultValue={state.data.odgovornaOsoba}
                rules={{
                  required: "Odgovorna osoba/e je obvezna",
                }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="odgovornaOsoba" />
          </Box>
          <Box mt="1em" h="85px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="oibTvrtke">
                OIB (tvrtke/obrta)
              </FormLabel>
              <Controller
                as={
                  <NumberFormat
                    allowLeadingZeros
                    decimalSeparator={false}
                    type="text"
                    customInput={Input}
                    maxLength={11}
                  ></NumberFormat>
                }
                borderColor={
                  errors.hasOwnProperty("oibTvrtke") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="oibTvrtke"
                placeholder="OIB (tvrtke/obrta)"
                defaultValue={state.data.oibTvrtke}
                rules={{
                  required: "OIB (tvrtke/obrta) je obvezan",
                  minLength: {
                    value: 11,
                    message: "OIB tvrtke mora sadržavati najmanje 11 znakova",
                  },
                }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="oibTvrtke" />
          </Box>
          <Box mt="1em" h="85px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="datumRegistracije">
                Datum registracije
              </FormLabel>
              <Controller
                as={<CustomDate autoOk={true} />}
                borderColor={
                  errors.hasOwnProperty("datumRegistracije") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="datumRegistracije"
                placeholder="Datum registracije"
                defaultValue={state.data.datumRegistracije}
                rules={{ required: "Datum registracije je obvezan" }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="datumRegistracije" />
          </Box>
          <Box mt="1em" h="85px" w={{ base: "100%", md: 1 / 2 }} px="15px">
            <FormControl isRequired>
              <FormLabel color="black" htmlFor="glavnaDjelatnost">
                Glavna djelatnost (prema NKD 2007)
              </FormLabel>
              <Controller
                as={<Input inputElementRef={register} />}
                borderColor={
                  errors.hasOwnProperty("glavnaDjelatnost") ? "red.500" : ""
                }
                control={control}
                color="black"
                name="glavnaDjelatnost"
                placeholder="Glavna djelatnost (prema NKD 2007)"
                defaultValue={state.data.glavnaDjelatnost}
                rules={{
                  required: "Glavna djelatnost (prema NKD 2007) je obvezna",
                }}
              />
            </FormControl>
            <ErrorMessage as={Error} errors={errors} name="glavnaDjelatnost" />
          </Box>
        </Flex>
      </Flex>
      <Flex
        d={{ base: "none", md: "flex" }}
        m="auto"
        mt="2em"
        maxW="600px"
        justifyContent="space-around"
        mb="2em"
      >
        <Button
          onClick={() => handleBack(getValues())}
          colorScheme="gray"
          boxShadow="-5px -5px gray"
          mr="15px"
          border="0"
          _hover={{
            boxShadow: "0px 0px gray",
            backgroundColor: "gray.600",
            transform: "translateY(-5px)",
            color: "white",
          }}
        >
          <span>
            <ArrowUpIcon />
          </span>
          Povratak
        </Button>
        <Button
          onClick={handleSubmit(handleNext)}
          colorScheme="green"
          boxShadow="-5px -5px green"
          border="0"
          _hover={{
            boxShadow: "0px 0px green",
            backgroundColor: "green.600",
            transform: "translateY(-5px)",
          }}
        >
          Nastavi
          <span>
            <ArrowDownIcon />
          </span>
        </Button>
      </Flex>
    </ABox>
  );
};

export default PodaciTvrtke;
